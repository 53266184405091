export async function readEvents({ calendarId, dateMin, dateMax }) {
  if (!calendarId || !dateMin || !dateMax) {
    return "A required parameter is missing";
  }

  const response = await fetch(
    // eslint-disable-next-line no-undef
    `/.netlify/functions/checkCalendar`,
    {
      method: `POST`,
      headers: {
        Accept: `*`,
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ calendarId, dateMin, dateMax }),
      redirect: `follow`,
    }
  );

  return response.json();
}
