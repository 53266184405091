/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Flex, jsx } from "theme-ui";
import { Input } from "./FormComponents";

export const UserDataForm = ({ userData, setUserData }) => {
  return (
    <>
      <Flex
        sx={{
          flexDirection: "column",
          alignContent: "start",
          flex: "1",
        }}
      >
        {Object.entries(userData).map((input) => {
          const [name, content] = input;
          return (
            <Input
              label={name}
              key={name}
              value={content.value}
              type={content.type || "text"}
              required
              onChange={(event) => {
                setUserData(name, event.nativeEvent.target.value);
              }}
            />
          );
        })}
      </Flex>
    </>
  );
};
