import { parseISO } from "date-fns";
import { eachDayOfInterval, sub } from "date-fns/fp";
import {
  compose,
  map,
  pick,
  flatten,
  dropLast,
  length,
  filter,
  where,
  equals,
  complement,
  prop,
  drop,
  over,
  lensProp,
  cond,
  lte,
} from "ramda";

const createInterval = compose(
  eachDayOfInterval,
  over(lensProp("end"), sub({ days: 1 })),
  map((x) => parseISO(Object.values(x)[0])),
  pick(["start", "end"])
);

export const parseDates = (eventId) =>
  compose(
    flatten,
    map(
      cond([
        [compose(equals(2), length), drop(1)],
        [compose(lte(3), length), compose(dropLast(1), drop(1))],
      ])
    ),
    map(createInterval),
    filter(complement(isEvent(eventId))),
    prop("data")
  );

const isEvent = (eventId) => where({ id: equals(eventId) });
