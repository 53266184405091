import React from "react";
import { ModalProvider } from "react-modal-hook";
import { Header, UIContext } from "components";
import { useFlat } from "./useFlat";
import { useTentativeEvent } from "./BookingApp/utils";
import clsx from "clsx";

export const centerInGrid = "col-start-2 col-span-10";
export const spanGrid = "col-span-full";

const link = "text-warmGray-700 flex-1 m-4 ml-0";

export const Layout = ({ children, contentBox = "", className = "" }) => {
  const [open, setOpen] = React.useState(false);
  const [flat, setFlat] = useFlat();

  const {
    eventId,
    createTentativeEvent,
    deleteTentativeEvent,
  } = useTentativeEvent(flat.calendarId);

  return (
    <ModalProvider>
      <UIContext.Provider
        value={{
          open,
          setOpen,
          flat,
          setFlat,
          eventId,
          createTentativeEvent,
          deleteTentativeEvent,
        }}
      >
        <div
          className={clsx(
            "grid min-h-screen gap-0 grid-cols-12 items-center",
            className
          )}
          style={{ gridTemplateRows: "max-content 1fr max-content" }}
        >
          <Header className="py-3" />
          <div
            className={clsx("grid col-span-full", contentBox)}
            style={{ grid: "inherit" }}
          >
            {children}
          </div>
          <div
            className="grid py-5 bg-warmGray-100 col-span-full mt-24"
            style={{ grid: "inherit" }}
          >
            <div className={clsx("flex flex-wrap", centerInGrid)}>
              <a className={link} href={`/datenschutzerklärung.pdf`}>
                Datenschutzerklärung
              </a>
              <a className={link} href={`/impressum.pdf`}>
                Impressum
              </a>
            </div>
          </div>
        </div>
      </UIContext.Provider>
    </ModalProvider>
  );
};
