/** @jsx jsx */
import { useMonth } from "@datepicker-react/hooks";
import { jsx } from "theme-ui";
import Day from "./Day";

const Month = ({
  year,
  month,
  firstDayOfWeek,
  className,
  monthLabelFormat,
  weekdayLabelFormat,
}) => {
  const { days, monthLabel, weekdayLabels } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat,
    weekdayLabelFormat,
  });

  return (
    <div className={className}>
      <div css={{ textAlign: "center", margin: "0 0 16px" }}>
        <strong>{monthLabel}</strong>
      </div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
          mb: 3,
          fontSize: 1,
        }}
      >
        {weekdayLabels.map((dayLabel) => (
          <div sx={{ textAlign: "center" }} key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
        }}
      >
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
};

export default Month;
