import React from "react";
import Img from "gatsby-image";

export const ImgWithOrientation = ({ aspectRatio, className, ...props }) => {
  let orientation;

  if (aspectRatio > 1 && aspectRatio < 2) orientation = `landscape`;
  else if (aspectRatio > 2) orientation = `wide-landscape`;
  else if (aspectRatio < 1 && aspectRatio > 0.5) orientation = `portrait`;
  else if (aspectRatio < 0.5) orientation = `tall-portrait`;
  else orientation = `square`;

  return <Img className={`${className} ${orientation}`} {...props} />;
};
