import React from "react";

const input = "bg-gray-200 border border-gray-300 rounded p-2";

export const Input = ({
  label,
  className,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <div className="mt-1 flex flex-col">
      <label htmlFor={label} className="text-gray-600 font-semibold">
        {label}
      </label>
      <input
        name={label}
        id={label}
        placeholder={placeholder || label}
        value={value}
        onChange={onChange}
        className={`${className} ${input}`}
        {...props}
      />
    </div>
  );
};

export const TextArea = ({
  label,
  className,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <div className="mt-1 flex flex-col">
      <label htmlFor={label} className="text-gray-600 font-semibold">
        {label}
      </label>
      <textarea
        name={label}
        id={label}
        placeholder={placeholder || label}
        value={value}
        onChange={onChange}
        className={`${className} ${input}`}
        {...props}
      />
    </div>
  );
};
