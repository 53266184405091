import towel from "./images/icons/flatFeatures/bedroom_bathroom/towel.svg";
import coffeeMachine from "./images/icons/flatFeatures/kitchen/coffee-machine.svg";
import fridge from "./images/icons/flatFeatures/kitchen/fridge.svg";
import microwave from "./images/icons/flatFeatures/kitchen/microwave.svg";
import oven from "./images/icons/flatFeatures/kitchen/oven.svg";
import toaster from "./images/icons/flatFeatures/kitchen/toaster.svg";

let products;

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV == "development") {
  import("./products_test.json").then((importedProducts) => {
    products = importedProducts.default;
  });
} else {
  import("./products.json").then((importedProducts) => {
    products = importedProducts.default;
  });
}

export const icons = [
  { icon: towel, name: "Handtücher und Bettwäsche" },
  { icon: coffeeMachine, name: "Kaffeemaschine" },
  { icon: fridge, name: "Kühlschrank" },
  { icon: microwave, name: "Mikrowelle" },
  { icon: oven, name: "Ofen" },
  { icon: toaster, name: "Toaster" },
];

export { products };
