/** @jsx jsx */

import React from "react";
import { FlatAndDateSelection, UserDataForm } from "./Forms";
import { Flex, Text, Button, jsx, Box } from "theme-ui";

import { differenceInCalendarDays, formatISO } from "date-fns";
import { sub, add } from "date-fns/fp";
import {
  isNil,
  set,
  not,
  lensPath,
  isEmpty,
  all,
  complement,
  map,
  prop,
  compose,
  values,
  any,
} from "ramda";
import {
  calculateTotal,
  checkout,
  useStep,
  readEvents,
  parseDates,
} from "./utils";
import { products as initialProducts } from "content";

export const BookingForm = React.forwardRef((props, ref) => {
  //Functionality Hooks
  const {
    index,
    navigation: { previous, next, go },
  } = useStep({ steps: 2 });

  const getStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return {
          header: "Wohnung",
          description:
            "Bitte wählen Sie erst die Wohnung und dann Ihre gewünschten Reisedaten aus.",
          component: (
            <FlatAndDateSelection
              flat={props.flat}
              setFlat={props.setFlat}
              dates={dates}
              setDates={setDates}
              deleteTentativeEvent={props.deleteTentativeEvent}
              eventId={props.eventId}
            />
          ),
          submitComponent: (
            <Button
              variant={not(datesPresent(dates)) ? "disabled" : "primary"}
              disabled={not(datesPresent(dates))}
              onClick={(e) => {
                e.preventDefault();
                verifyDatesAvailable(props.eventId || "", dates).then(
                  (value) => {
                    if (value == true) {
                      props.createTentativeEvent(dates);
                      next();
                    } else {
                      setError(
                        "Leider ist die Wohnung an diesen Daten schon vergeben. Bitte wählen Sie andere Daten aus."
                      );
                    }
                  }
                );
              }}
            >
              Weiter
            </Button>
          ),
        };
      // case 1:
      //   return {
      //     header: "Extras",
      //     description: "Bitte wählen Sie Ihre gewünschten Extras aus.",
      //     component: (
      //       <ProductList
      //         products={products}
      //         setValue={setProductQuantityImmutably}
      //         max={view(lensPath(["Nacht", "quantity"]), products)}
      //       />
      //     ),
      //   };
      case 1:
        return {
          header: "Persönliche Daten",
          description: "Bitte geben Sie Ihre persönlichen Daten an.",
          component: (
            <UserDataForm
              userData={userData}
              setUserData={setUserDataImmutably}
            />
          ),
          submitComponent: (
            <Button
              as="input"
              type="submit"
              value="Zur Zahlung"
              variant={checkEmpty(userData) ? "disabled" : "primary"}
              disabled={checkEmpty(userData)}
            />
          ),
        };
    }
  };

  //State Hooks
  const [dates, setDates] = React.useState([null, null]);
  const [products, setProducts] = React.useState(initialProducts);
  const [userData, setUserData] = React.useState({
    Vorname: { value: "" },
    Nachname: { value: "" },
    Email: { value: "", type: "email" },
    Handynummer: { value: "", type: "number" },
    Adresse: { value: "" },
    Stadt: { value: "" },
    Postleitzahl: { value: "", type: "number" },
  });
  const [error, setError] = React.useState();
  const totalPrice = calculateTotal(products);

  const isNotNil = complement(isNil);
  const datesPresent = all(isNotNil);
  //Checks the userData for empty values and disables the "Zur Zahlung" Button when any empty values are found
  const checkEmpty = compose(any(isEmpty), values, map(prop("value")));
  const verifyDatesAvailable = async (eventId, dates) => {
    return isEmpty(
      parseDates(eventId)(
        await readEvents({
          calendarId: props.flat.calendarId,
          dateMin: formatISO(add({ days: 1 })(dates[0])),
          dateMax: formatISO(sub({ days: 1 })(dates[1])),
        })
      )
    );
  };

  //This is an immutable state update function
  const setProductQuantityImmutably = (name, newValue) =>
    setProducts(set(lensPath([name, "quantity"]), newValue, products));
  const setUserDataImmutably = (name, newValue) => {
    setUserData({
      ...userData,
      [name]: { ...userData[name], value: newValue },
    });
  };

  React.useEffect(() => {
    if (datesPresent(dates)) {
      setProductQuantityImmutably(
        "Nacht",
        differenceInCalendarDays(dates[1], dates[0])
      );
      props.deleteTentativeEvent();
      go(0);
    }
  }, [dates]);

  const onSubmit = async (e, eventId) => {
    e.preventDefault();
    checkout(products, userData.Email.value, {
      calendarId: props.flat.calendarId,
      eventId,
      start: formatISO(dates[0]),
      end: formatISO(dates[1]),
      ...map(prop("value"))(userData),
    });
  };

  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
      ref={ref}
      onSubmit={(e) => onSubmit(e, props.eventId)}
      className={props.className}
      as="form"
      {...props}
    >
      <FormContent index={index} sx={{ overflow: "auto", flex: "1" }}>
        <Box sx={{ flex: "0 0 min-content" }}>
          <Text as="h3">{getStep(index).header}</Text>
          <Text as="p" py={2}>
            {getStep(index).description}
          </Text>
        </Box>
        {getStep(index).component}
      </FormContent>
      <div
        sx={{
          px: [2, 3],
          py: [3],
          color: "red",
          fontWeight: "bold",
          flex: "0 0 min-content",
        }}
      >
        {error}
      </div>
      <Box
        sx={{
          flex: "0 0 min-content",
          paddingY: [3],
          borderTop: "1px solid black",
        }}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Text
            sx={{
              fontSize: [3, 4],
            }}
          >
            Gesamt: {totalPrice}€
          </Text>
          <Flex>
            {index > 0 && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                sx={{ marginRight: 3 }}
                variant={index == 0 ? "disabled" : "primary"}
              >
                Zurück
              </Button>
            )}
            {getStep(index).submitComponent || (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
              >
                Weiter
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
});

const FormContent = ({ className, children }) => {
  return (
    <Box
      sx={{
        flex: "1 1",
        minHeight: "0",
        overflow: "auto",
      }}
      className={className}
    >
      {children}
    </Box>
  );
};
