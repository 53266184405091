export async function deleteEvent(calendarId, eventId) {
  const response = await fetch(
    // eslint-disable-next-line no-undef
    `/.netlify/functions/deleteEvent`,
    {
      method: `POST`,
      headers: {
        Accept: `*`,
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ calendarId, eventId }),
      redirect: `follow`,
    }
  );

  const data = await response.json();
  return data;
}
