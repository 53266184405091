import React from "react";
import styled from "@emotion/styled";
import ScrollContainer from "react-indiana-drag-scroll";

import { ImgWithOrientation } from "./utils";
import {
  ArrowForwardIosTwoTone,
  ArrowBackIosTwoTone,
} from "@material-ui/icons";
import clsx from "clsx";

const GalleryGrid = styled(ScrollContainer)`
  display: grid;
  grid-auto-flow: dense column;
  grid-auto-columns: minmax(15rem, 1fr);
  overflow: auto;
  grid-gap: 0.5rem;

  .landscape {
    grid-column-end: span 2;
  }

  .wide-landscape {
    grid-column-end: span 4;
  }

  .portrait {
    grid-row-end: span 2;
    grid-column-end: span 2;
  }

  .tall-portrait {
    grid-row-end: span 4;
  }
`;

export const DynamicGridGallery = ({ className, images, height }) => {
  const container = React.useRef(null);
  const [position, setPosition] = React.useState(0);
  const [lastPosition, setLastPosition] = React.useState(0);

  React.useLayoutEffect(() => {
    setPosition(container.current.getElement().scrollLeft);
    setLastPosition(
      container.current.getElement().scrollWidth - window.innerWidth
    );
  }, []);

  React.useEffect(() => {
    container.current
      .getElement()
      .scrollTo({ left: position, behavior: "smooth" });
  }, [position]);

  const scroll = (direction) => {
    switch (direction) {
      case "forward": {
        const nextPosition = position + window.innerWidth;
        nextPosition > lastPosition
          ? setPosition(lastPosition)
          : setPosition(position + window.innerWidth);
        break;
      }
      case "back": {
        const nextPosition = position - window.innerWidth;
        nextPosition < 0
          ? setPosition(0)
          : setPosition(position - window.innerWidth);
        break;
      }
      default:
        break;
    }
  };

  return (
    <div
      className={clsx(className, "items-center grid")}
      style={{
        gridTemplateColumns: "max-content 10fr max-content",
        gridTemplateRows: height,
      }}
    >
      <button
        onClick={() => scroll("back")}
        className={clsx(
          "col-start-1 row-start-1 ml-5 z-10 p-2 lg:p-3 rounded-full bg-orange-300 shadow-lg text-orange-900 transition-opacity duration-500",
          position <= 0 && "opacity-50"
        )}
        disabled={position <= 0}
      >
        <ArrowBackIosTwoTone fontSize="default" />
      </button>

      <GalleryGrid
        horizontal
        hideScrollbars={true}
        ref={container}
        onEndScroll={() => {
          setPosition(container.current.getElement().scrollLeft);
        }}
        className="col-span-full row-start-1 h-full"
      >
        {images.map((image) => (
          <ImgWithOrientation
            alt={image.alt}
            key={image.src}
            aspectRatio={image.aspectRatio}
            fluid={image}
          />
        ))}
      </GalleryGrid>

      <button
        onClick={() => scroll("forward")}
        className={clsx(
          "col-start-3 row-start-1 mr-5 z-10 p-2 lg:p-3 rounded-full bg-orange-300 shadow-lg text-orange-900 transition-opacity duration-500",
          position >= lastPosition && "opacity-50"
        )}
        disabled={position >= lastPosition}
      >
        <ArrowForwardIosTwoTone fontSize="default" />
      </button>
    </div>
  );
};
