import { loadStripe } from "@stripe/stripe-js";
import { pick, map, compose, propSatisfies, gt, __, filter } from "ramda";
import { createCheckoutSession } from "./createCheckoutSession";

// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const flattenProducts = compose(
  map(pick(["price", "quantity"])),
  filter(propSatisfies(gt(__, 0), "quantity")),
  Object.values
);

export const checkout = async (items, email, calendarData) => {
  const { id } = await createCheckoutSession(
    flattenProducts(items),
    email,
    calendarData
  );
  const stripe = await stripePromise;

  const { error } = await stripe.redirectToCheckout({
    sessionId: id,
  });

  if (error) {
    console.warn(`Error:`, error);
  }
};
