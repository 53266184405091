import React from "react";
import clsx from "clsx";

const variants = {
  default: "p-4 lg:p-8",
  edgeToEdge: "",
};

export const Card = ({
  className,
  children,
  variant = "default",
  ...props
}) => {
  return (
    <div
      className={clsx(
        "rounded-sm flex flex-col overflow-hidden shadow-md transition-all duration-200",
        variants[variant],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
