import React from "react";
import clsx from "clsx";
import BackgroundImg from "gatsby-background-image";

export const Hero = ({ className, img, children, ...props }) => {
  return (
    <div className={clsx("grid grid-rows-6 grid-cols-12", className)}>
      <BackgroundImg
        loading="eager"
        fluid={img}
        {...props}
        className="col-span-full row-start-1 row-end-6 shadow-md"
      />
      <div className="col-start-2 col-end-12 2xl:col-start-4 2xl:col-end-10 row-start-5 row-end-7 z-10 bg-orange-100 flex p-4 md:p-10 lg:p-20 items-center shadow-xl rounded">
        {children}
      </div>
    </div>
  );
};
