import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { prop, indexBy, compose } from "ramda";

export const useFlat = () => {
  //Get the flat information from graphql => transform them into title: calendarId
  const calendarIdQuery = useStaticQuery(graphql`
    query calendarIdQuery {
      allWohnungenJson {
        nodes {
          title
          calendarId
        }
      }
    }
  `);

  const flatObject = compose(indexBy(prop("title")));
  const flats = flatObject(calendarIdQuery.allWohnungenJson.nodes);
  const [flat, setFlat] = React.useState({ title: "", calendarId: "" });

  const setFlatBasedOnString = (flatName) => {
    setFlat(flatName ? flats[flatName] : { title: "", calendarId: "" });
  };

  return [flat, setFlatBasedOnString];
};
