import { path } from "ramda";

export const destructureImageData = (imageData) => {
  return imageData.nodes.map((node) => {
    return {
      alt: node.alt,
      ...path(["childImageSharp", "fluid"], node),
    };
  });
};
