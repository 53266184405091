import React from "react";
import { Input, TextArea } from "./BookingApp/Forms/FormComponents";
import { Card } from "components";
import { Button } from "./Button";

export const ContactForm = ({ className }) => {
  const [values, setValues] = React.useState({
    name: "",
    message: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Card
      as="form"
      className={className}
      data-netlify="true"
      name="Kontakt"
      action="/kontaktErfolg"
      id="contact"
      method="post"
      netlify-honeypot="field"
    >
      <label className="hidden">
        Wenn Sie ein Mensch sind tragen Sie hier nichts ein:
        <input name="field" />
      </label>
      <div className="flex flex-col lg:flex-row justify-between">
        <h2 className="text-2xl lg:text-4xl">Haben Sie Fragen?</h2>
        <a
          href="tel:+49 1573 1702620"
          className="text-blue-900 px-2 py-1 my-2 text-sm lg:text-xl lg:self-end border border-blue-800 bg-blue-200 rounded"
        >
          +49 1573 1702620
        </a>
      </div>
      <h3 className="mt-10 text-xl border-b-4 pb-2 border-orange-300 max-w-max">
        Oder schreiben Sie uns:
      </h3>
      <div className="space-y-6 mt-4">
        <Input
          name="name"
          label="Vor- und Nachname"
          placeholder="Max Mustermann"
          type="text"
          value={values.name}
          onChange={handleChange}
          required
        />
        <TextArea
          rows={8}
          name="message"
          label="Ihre Nachricht"
          type="text"
          placeholder="Schreiben Sie uns bei Fragen oder Problemen."
          value={values.message}
          onChange={handleChange}
          required
        />
        <Input
          name="email"
          label="E-Mail"
          placeholder="max.mustermann@gmail.com"
          value={values.email}
          onChange={handleChange}
          type="email"
          required
        />
      </div>
      <Button className="self-end mt-6" type="submit">
        Abschicken
      </Button>
    </Card>
  );
};
