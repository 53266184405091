/** @jsx jsx */
import React from "react";
import { useDay } from "@datepicker-react/hooks";
import { jsx, Button } from "theme-ui";
import DatepickerContext from "./datepickerContext";
import { lighten } from "@theme-ui/color";

const getColor = (
  isSelected,
  isSelectedStartOrEnd,
  isWithinHoverRange,
  isDisabled
) => {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } else if (isSelected) {
      return selectedColor;
    } else if (isWithinHoverRange) {
      return rangeHoverColor;
    } else if (isDisabled) {
      return disabledColor;
    } else {
      return normalColor;
    }
  };
};

function Day({ dayLabel, date }) {
  const dayRef = React.useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    disabled,
  } = React.useContext(DatepickerContext);

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate
  );

  return (
    <Button
      variant={disabledDate ? "hiddenDisabled" : "hidden"}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={disabledDate ? "-1" : tabIndex}
      type="button"
      ref={dayRef}
      sx={{
        py: 1,
        color: (theme) =>
          getColorFn({
            selectedFirstOrLastColor: "#FFFFFF",
            normalColor: theme.colors.text,
            selectedColor: "#FFFFFF",
            rangeHoverColor: "#FFFFFF",
            disabledColor: "red",
          }),
        background: (theme) =>
          getColorFn({
            selectedFirstOrLastColor: theme.colors.primary,
            normalColor: "#FFFFFF",
            selectedColor: theme.colors.primary,
            rangeHoverColor: lighten(theme.colors.primary, 0.05),
            disabledColor: "#FFFFFF",
          }),
      }}
    >
      {dayLabel}
    </Button>
  );
}

export default Day;
