import { path } from "ramda";

export async function createEvent(calendarId, event) {
  const response = await fetch(
    // eslint-disable-next-line no-undef
    `/.netlify/functions/createEvent`,
    {
      method: `POST`,
      headers: {
        Accept: `*`,
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ calendarId, event }),
      redirect: `follow`,
    }
  );

  const data = await response.json();
  const createdEvent = path(["data", "data"], data);
  return [response, createdEvent];
}
