/**@jsx jsx */
import { Grid, jsx, Box, Text, Flex, Card } from "theme-ui";
import { lighten } from "@theme-ui/color";
import questionMark from "./question-mark.svg";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Slider, Checkbox } from "@material-ui/core";
import { prop } from "ramda";

const Tooltip = ({ children, icon, alt }) => (
  <Tippy content={children} touch="true">
    <img src={icon} alt={alt} />
  </Tippy>
);

export const SliderItem = ({ name, content, value, ...props }) => {
  return (
    <Box sx={{ marginY: 3 }}>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          margin: "4 0 3 0",
        }}
      >
        <Text as="label" htmlFor={name}>
          {name}
        </Text>
        {content.description && (
          <Tooltip icon={questionMark} alt="Tooltip">
            {content.description}
          </Tooltip>
        )}
      </Flex>

      <Grid
        sx={{
          gridTemplateColumns: "50px 1fr 50px",
          alignItems: "center",
        }}
      >
        <span>{content.cost}€</span>
        <Slider step={1} valueLabelDisplay="auto" {...props} value={value} />
        <span sx={{ justifySelf: "end" }}>{value * content.cost}€</span>
      </Grid>
    </Box>
  );
};

export const UpgradeItem = ({ name, content, ...props }) => {
  return (
    <Card
      variant="dense"
      sx={{
        borderRadius: 3,
        gridTemplateColumns: "max-content max-content",
        alignItems: "center",
        justifyItems: "center",
        gridGap: 1,
        ...(prop("checked", props)
          ? {
              border: "1px solid #6FB243",
              backgroundColor: lighten("#6FB243", 0.45),
            }
          : {
              border: "1px solid black",
              backgroundColor: lighten("#000", 0.98),
            }),
      }}
      as={Grid}
    >
      <Text as="label" htmlFor={name}>
        {name}
      </Text>
      {content.description && (
        <Tooltip icon={questionMark} alt="Tooltip">
          {content.description}
        </Tooltip>
      )}
      <div sx={{ fontSize: 1 }}>+{content.cost}€ je Nacht</div>
      <Checkbox {...props} color="primary" />
    </Card>
  );
};
