/* eslint-disable no-undef */
module.exports = {
  siteMetadata: {
    title: `Ferienwohnung Vogtland`,
    description: `Verbringen sie ihren nächsten Urlaub im wunderschönen Vogtland und mieten Sie ihre eigene Ferienwohnung.`,
    siteUrl: `https://ferienwohnung-vogtland.com`,
    author: `@philtech`,
  },
  plugins: [
    "gatsby-plugin-postcss",
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-170848178-1",
        anonymize: true,
        respectDNT: true,
        head: true,
      },
    },
    {
      resolve: "gatsby-plugin-module-resolver",
      options: {
        root: "./", // <- will be used as a root dir
        aliases: {
          components: "./src/components", // <- will become ./src/components
          utils: "./src/utils", // <- will become ./src/utils
          content: "./content",
          static: {
            root: "./public", // <- will used as this alias' root dir
            alias: "./static", // <- will become ./public/static
          },
        },
      },
    },
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-modal-routing`,
    `gatsby-plugin-robots-txt`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `./content`,
      },
    },
    `gatsby-transformer-remark`,
    `gatsby-transformer-json`,
    `gatsby-transformer-sharp`,
    {
      resolve: "gatsby-plugin-theme-ui",
    },
    `gatsby-plugin-sharp`,
    `gatsby-plugin-preact`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Ferienwohnung Vogtland`,
        short_name: `Fewo`,
        start_url: `/`,
        background_color: `#FFB976`,
        theme_color: `#FFB976`,
        display: `minimal-ui`,
        icon: `content/images/logo.png`, // This path is relative to the root of the site.
      },
    },
    `gatsby-plugin-emotion`,
    {
      resolve: `gatsby-plugin-eslint`,
      options: {
        stages: [`develop`],
        options: {
          emitWarning: true,
          failOnError: false,
        },
      },
    },
  ],
};
