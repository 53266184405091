/* eslint-disable no-unused-vars */
/**@jsx jsx */
import React from "react";
import { useState } from "react";
import { jsx, IconButton, Flex, Grid } from "theme-ui";
import { START_DATE, useDatepicker } from "@datepicker-react/hooks";
import Month from "./Month";
import DatepickerContext from "./datepickerContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { format } from "date-fns";
import { de } from "date-fns/locale";

export const Datepicker = ({ config, dates, setDates, disabled }) => {
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const index = useBreakpointIndex();

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dates[0],
    endDate: dates[1],
    minBookingDays: "2",
    focusedInput: focusedInput,
    onDatesChange: handleDateChange,
    ...config,
  });

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDates([data.startDate, data.endDate]);
      setFocusedInput(START_DATE);
    } else {
      setDates([data.startDate, data.endDate]);
      setFocusedInput(data.focusedInput);
    }
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        disabled,
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        }}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            gridColumn: "1 / -1",
          }}
        >
          <NavButton onClick={goToPreviousMonths} label="Voheriger Monat">
            <ArrowBackIcon />
          </NavButton>
          <NavButton onClick={goToNextMonths} label="Nächster Monat">
            <ArrowForwardIcon />
          </NavButton>
        </Flex>

        {activeMonths.map((month) => (
          <Month
            // sx={{ minWidth: "250px" }}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
            monthLabelFormat={(date) =>
              format(date, "MMMM yyyy", { locale: de })
            }
            weekdayLabelFormat={(date) =>
              format(date, "eeeeee", { locale: de })
            }
          />
        ))}
      </Grid>
    </DatepickerContext.Provider>
  );
};

const NavButton = ({ children, onClick }) => {
  return (
    <IconButton type="button" onClick={onClick}>
      {children}
    </IconButton>
  );
};
