import React from "react";
import {
  WifiTwoTone,
  TvTwoTone,
  KingBedTwoTone,
  PoolTwoTone,
  Brightness4TwoTone,
  WeekendTwoTone,
  KitchenTwoTone,
} from "@material-ui/icons";
import clsx from "clsx";

const Label = ({ children, className }) => (
  <label className={clsx(className, "w-max")}>{children}</label>
);

export const FlatFeatures = ({ className }) => {
  return (
    <div
      className={clsx(
        className,
        "grid p-4 bg-warmGray-50 gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 border-l-8 border-orange-400 rounded shadow-md"
      )}
    >
      <Label>
        <WifiTwoTone /> Gratis Wlan
      </Label>
      <Label>
        <TvTwoTone /> Satellitenfernsehen
      </Label>
      <Label>
        <KingBedTwoTone /> Doppelbett
      </Label>
      <Label>
        <WeekendTwoTone /> Schlafcouch
      </Label>
      <Label>
        <Brightness4TwoTone /> Außenjalousien
      </Label>
      <Label>
        <KitchenTwoTone /> Voll ausgestattete Küche
      </Label>
      <Label>
        <PoolTwoTone /> Pool im Preis inbegriffen
      </Label>
    </div>
  );
};
