import React from "react";

export const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={`bg-orange-200 hover:bg-orange-300 text-orange-900 shadow hover:shadow-lg  px-3 py-2 lg:px-4 lg:py-2 rounded clickable font-semibold transition-all duration-100 inline-flex items-center justify-center ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
