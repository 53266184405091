import React from "react";
import Img from "gatsby-image";
import { Card, link } from "components";
import clsx from "clsx";
import { BurstModeTwoTone } from "@material-ui/icons";
import { Link } from "gatsby";

export const CoverCard = ({ content, className, to }) => {
  return (
    <Card
      className={clsx(className, "rounded shadow-xl hover:shadow-2xl")}
      style={{ minWidth: "300px" }}
      to={to}
      variant="edgeToEdge"
    >
      <div className="border-l-8 border-orange-400 bg-warmGray-100 flex justify-between items-center">
        <h4 className="font-semibold px-2 py-4 lg:py-6">{content.title}</h4>
      </div>
      <div className="flex relative flex-1">
        <Img
          fluid={content.cover.childImageSharp.fluid}
          alt={content.cover.alt}
          className="flex-1"
        />
        <Link
          to={to}
          className={clsx(
            "absolute bottom-4 right-4 md:toggleVisibility",
            link
          )}
        >
          <span style={{ display: "var(--visibility)" }} className="mr-2">
            Mehr Bilder
          </span>
          <BurstModeTwoTone />
        </Link>
      </div>
    </Card>
  );
};
