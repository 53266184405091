import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import clsx from "clsx";

export const Title = ({ className, ...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { regex: "/logo/" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <h1 className={clsx("flex items-center", className)} {...props}>
      <Img
        fixed={data.file.childImageSharp.fixed}
        style={{ display: "hidden" }}
        className="mr-3 hidden md:inline-block"
      />
      <Link className="text-gray-800" to={props.href}>
        {data.site.siteMetadata.title}
      </Link>
    </h1>
  );
};
