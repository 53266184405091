export async function createCheckoutSession(items, email, calendarData) {
  const response = await fetch(
    // eslint-disable-next-line no-undef
    `/.netlify/functions/stripeCheckout`,
    {
      method: `POST`,
      headers: {
        Accept: `*`,
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ items, email, calendarData }),
      redirect: `follow`,
    }
  );
  const data = await response.json();
  return data;
}
