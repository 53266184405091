import React from "react";
import { formatISO } from "date-fns";
import { add } from "date-fns/esm/fp";
import { prop } from "ramda";
import { createEvent, deleteEvent } from "/";

export const useTentativeEvent = (calendarId) => {
  const [eventId, setEventId] = React.useState();

  const createTentativeEvent = async (dates) => {
    const [, createdEvent] = await createEvent(calendarId, {
      start: { date: formatISO(dates[0], { representation: "date" }) },
      end: {
        date: formatISO(add({ days: 1 }, dates[1]), { representation: "date" }),
      },
      summary: `Vorläufig`,
      status: "tentative",
    });
    setEventId(prop("id", createdEvent));
  };

  const deleteTentativeEvent = () => {
    eventId && deleteEvent(calendarId, eventId);
    setEventId(null);
  };

  return { eventId, setEventId, createTentativeEvent, deleteTentativeEvent };
};
