/** @jsx jsx */

import React from "react";
import { jsx, Spinner, Radio, Flex, Label, Box } from "theme-ui";
import { Datepicker } from "./FormComponents/Datepicker/Datepicker";
import { not, isEmpty, isNil } from "ramda";
import { formatISO, add } from "date-fns/esm//fp";
import { readEvents, parseDates } from "../utils";

export const FlatAndDateSelection = ({
  flat,
  setFlat,
  dates,
  setDates,
  deleteTentativeEvent,
  eventId,
}) => {
  const [unavailableDates, setUnavailableDates] = React.useState();

  const datepickerConfig = {
    //Only allow dates in the present and future
    minBookingDate: new Date(),
    //Only allow dates for one year
    maxBookingDate: add({ years: 1 })(new Date()),
    //Unavailable dates as based on the google calendar of each flat
    unavailableDates,
  };

  React.useEffect(() => {
    async function fetchData() {
      const data = await readEvents({
        calendarId: flat.calendarId,
        dateMin: formatISO(datepickerConfig.minBookingDate),
        dateMax: formatISO(datepickerConfig.maxBookingDate),
      });
      setUnavailableDates(parseDates(eventId || "")(await data));
    }
    setUnavailableDates();
    deleteTentativeEvent();
    setDates([null, null]);
    flat.calendarId && fetchData();
  }, [flat]);

  return (
    <>
      <Flex sx={{ py: 3, flexWrap: "wrap" }}>
        <Label sx={{ width: "max-content", m: 2 }}>
          <Radio
            name="wohnung"
            value="Wohnung EG"
            checked={flat.title == "Wohnung EG"}
            onChange={() => setFlat("Wohnung EG")}
          />
          Wohnung Erdgeschoss
        </Label>
        <Label sx={{ width: "max-content", m: 2 }}>
          <Radio
            name="wohnung"
            value="Wohnung OG"
            checked={flat.title == "Wohnung OG"}
            onChange={() => setFlat("Wohnung OG")}
          />
          Wohnung Obergeschoss
        </Label>
      </Flex>
      <Box sx={{ overflow: "auto" }}>
        {not(isEmpty(flat.title)) ? (
          unavailableDates ? (
            <Datepicker
              config={datepickerConfig}
              dates={dates}
              setDates={setDates}
              disabled={isNil(unavailableDates) && true}
            />
          ) : (
            <Spinner sx={{ display: "block" }} title="Laden" />
          )
        ) : null}
      </Box>
    </>
  );
};
