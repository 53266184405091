import React from "react";
import { Title, link } from "components";
import clsx from "clsx";

export const Header = ({ className, ...props }) => {
  return (
    <div
      className={clsx("grid bg-warmGray-100 col-span-full")}
      style={{ grid: "inherit" }}
      {...props}
    >
      <div
        className={clsx("flex items-center col-start-2 col-span-10", className)}
      >
        <Title
          href="/"
          className="flex-grow mr-2 text-md font-semibold lg:text-3xl"
        />
        <a
          className={clsx(link, "font-semibold")}
          href="https://www.e-domizil.de/ferienwohnung/362401/fewo-gisela-treuen-vogtland-sachsen-sachsen-deutschland/"
        >
          Buchen
        </a>
      </div>
    </div>
  );
};
